import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SyncIcon from '../../assets/icons/redo-alt.svg';
import { OfflineContext } from '../../global/context';
import { text } from '../../config';

const SyncProgress = (props) => {
    const { syncing } = props;
    const offline = useContext(OfflineContext);

    // sync done and online status
    if (!syncing && !offline) return null;

    // we are offline
    if (offline) {
        return (
            <div className="font-size-smaller text-white d-flex align-items-center">
                <div className="">{text.offlineStatus}</div>
            </div>
        );
    }
    // we are syncing
    return (
        <div className="font-size-smaller text-white d-flex align-items-center">
            {text.syncing} <img src={SyncIcon} width="12" height="12" alt="" className="animation-rotate ml-2" />
        </div>
    );
};

SyncProgress.propTypes = {
    syncing: PropTypes.bool.isRequired,
};

export default SyncProgress;
