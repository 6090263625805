export const cssKeys = {
    errorInput: 'form-error',
    errorMsg: 'form-error-msg',
};
export const databaseBasePath = 'measurements_v2';
export const appStorageKey = 'sse-app-data';
export const notificationShowTime = 5000;
export const itemsPerPage = 10;
export const editTouchDuration = 300;
export const defaultFormData = { consumned: '', produced: '', returned: '', year: new Date().getFullYear(), month: new Date().getMonth() + 1 };
export const monthsLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

export const text = {
    measurementSaved: process.env.REACT_APP_TEXT_MEASUREMENT_SAVED,
    errorMessage: process.env.REACT_APP_TEXT_ERROR_MESSAGE,
    ntLabel: process.env.REACT_APP_TEXT_NT_LABEL,
    vtLabel: process.env.REACT_APP_TEXT_VT_LABEL,
    closeForm: process.env.REACT_APP_TEXT_CLOSE_FORM,
    save: process.env.REACT_APP_TEXT_SAVE,
    loadMore: process.env.REACT_APP_TEXT_LOAD_MORE,
    delItem: process.env.REACT_APP_TEXT_DEL_ITEM,
    syncing: process.env.REACT_APP_TEXT_SYNCING,
    offlineStatus: process.env.REACT_APP_TEXT_OFFLINE_STATUS,
    monthLabel: process.env.REACT_APP_TEXT_MONTH_LABEL,
    yearLabel: process.env.REACT_APP_TEXT_YEAR_LABEL,
    lastLabel: process.env.REACT_APP_TEXT_LAST_LABEL,
    inputOne: process.env.REACT_APP_TEXT_INPUT_ONE,
    inputTwo: process.env.REACT_APP_TEXT_INPUT_TWO,
    inputThree: process.env.REACT_APP_TEXT_INPUT_THREE,
    inputFour: process.env.REACT_APP_TEXT_INPUT_FOUR,
    inputFive: process.env.REACT_APP_TEXT_INPUT_FIVE,
};
export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
