import React, { useContext, useEffect, useState } from 'react';
import SyncProgress from '../SyncProgress';
import HeaderBox from './Box';
import { MeasurementListEditableContext, MeasurementsContext } from '../../global/context';

const Logo = () => (
    <svg width="131" height="50" viewBox="0 0 707 270" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M498.773 35.3173H381.981C343.065 35.3173 305.981 64.1923 295.148 102.692H486.981L498.773 35.3173ZM501.356 249.609C512.981 262.276 529.773 269.276 548.606 269.276H665.731L677.523 201.901H485.648C484.315 220.234 489.898 237.151 501.356 249.609ZM488.356 185.984H680.648L692.44 118.609H500.148L488.356 185.984ZM589.898 35.3173C550.981 35.3173 513.898 64.1923 503.065 102.692H694.898L706.69 35.3173H589.898ZM205.231 120.317L85.148 111.817C82.4814 130.567 86.9397 148.234 97.6897 161.734C108.231 174.984 124.106 182.984 142.356 184.276L262.398 192.776C265.065 174.026 260.648 156.359 249.856 142.859C239.315 129.609 223.44 121.609 205.231 120.317ZM57.3147 269.276H174.106C212.356 269.276 250.231 239.776 260.94 201.901H69.1063L57.3147 269.276ZM265.856 269.276H382.648C421.565 269.276 458.648 240.401 469.481 201.901H277.648L265.856 269.276ZM413.773 120.317L293.69 111.817C291.023 130.567 295.481 148.234 306.231 161.734C316.773 174.984 332.648 182.984 350.856 184.276L470.94 192.776C473.606 174.026 469.19 156.359 458.398 142.859C447.856 129.609 431.981 121.609 413.773 120.317ZM290.231 35.3173H173.44C134.523 35.3173 97.4397 64.1923 86.6064 102.692H278.44L290.231 35.3173Z" fill="white" />
        <path d="M127.083 0.858978L0.874634 197.192L32.208 0.858978H127.083Z" fill="white" />
    </svg>
);

const currentYear = new Date().getFullYear();

const Header = () => {
    const measurementListEditable = useContext(MeasurementListEditableContext);
    const measurements = useContext(MeasurementsContext);
    const [consumned, setConsumned] = useState('-');
    const [produced, setProduced] = useState('-');
    const [returned, setReturned] = useState('-');

    useEffect(() => {
        // get measurements from current year
        const currentYearMeasurements = measurements.items.filter((item) => item.year === currentYear);
        // sum consumned values
        setConsumned(currentYearMeasurements.reduce((acc, curr) => acc + Number(curr.consumned), 0));
        // sum produced values
        setProduced(currentYearMeasurements.reduce((acc, curr) => acc + Number(curr.produced), 0));
        // sum returned values
        setReturned(currentYearMeasurements.reduce((acc, curr) => acc + Number(curr.returned), 0));
    }, [measurements]);

    return (
        <div className="container mt-5 mb-4">
            <div className="d-flex mb-6 justify-content-between align-items-center">
                <Logo />
                <SyncProgress syncing={consumned === '-'} />
            </div>
            <div className="row no-gutters">
                <HeaderBox label="Spotrebovaná" padding="pr-1" value={consumned}></HeaderBox>
                <HeaderBox label="Vyrobená" padding="pl-1" value={produced}></HeaderBox>
                <HeaderBox label="Vrátená" padding="pl-1" value={returned}></HeaderBox>
            </div>
            {measurementListEditable.value ? (
                <button
                    onClick={() => {
                        measurementListEditable.setValue(false);
                    }}
                    type="button"
                    className="btn-done d-flex align-items-center font-size-small bg-white font-weight-bold position-top position-right transition mt-3 mr-3 position-absolute"
                >
                    Hotovo
                </button>
            ) : (
                ''
            )}
        </div>
    );
};

export default Header;
