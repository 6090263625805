import { text, cssKeys } from '../config';

const createErrorMessageElement = () => {
    const element = document.createElement('div');
    element.textContent = text.errorMessage;
    element.classList.add(cssKeys.errorMsg);
    return element;
};

const toggleInputErrorMsg = (input) => {
    // is input valid?
    if (input.validity.valid) {
        // had an error?
        if (input.classList.contains(cssKeys.errorInput)) {
            input.nextElementSibling.remove();
            input.classList.remove(cssKeys.errorInput);
        }
        return;
    }
    // input already has an error
    if (input.classList.contains(cssKeys.errorInput)) return;
    // input is invalid and has no error => create and show error message
    const errorMessageElement = createErrorMessageElement();
    // add error class to input
    input.classList.add(cssKeys.errorInput);
    // append error message to input
    input.insertAdjacentElement('afterend', errorMessageElement);
};

const Validate = {
    form: (form) => {
        let isValid = true;
        // validate inputs
        const controls = [...form.elements];
        controls.forEach((control) => {
            // is input valid?
            if (control.validity.valid === false) {
                isValid = false;
            }
            // toggle error message
            toggleInputErrorMsg(control);
        });

        return isValid;
    },
    input: toggleInputErrorMsg,
};

export default Validate;
