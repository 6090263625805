import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Select = (props) => {
    const { label, id, defaultValue } = props;
    const [value, setValue] = useState(defaultValue);

    return (
        <label htmlFor={id} className="form-label d-block font-weight-bold">
            {label}
            <select
                name={id}
                id={id}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                className="form-control mt-2"
                value={value}
            >
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
            </select>
        </label>
    );
};

Select.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    defaultValue: PropTypes.number.isRequired,
};

export default Select;
