export const parseData = (data) => {
    const years = Object.keys(data);
    const parsedData = {
        max: 0,
        items: [],
    };

    years.forEach((year) => {
        const months = Object.keys(data[year]);

        months.forEach((month) => {
            // calculate values for nt and vt
            const { consumned, produced, returned } = data[year][month];
            // set max value
            parsedData.max = Math.max(parsedData.max, consumned);
            // return new item
            parsedData.items.push({
                consumned,
                produced,
                returned,
                year: Number(year),
                month,
            });
        });
    });

    return parsedData;
};

export const parseYears = (data) => {
    const years = { items: {}, max: 0 };

    // parse month data
    data.items.forEach((item) => {
        // sest item value
        const value = Number(item.consumned);
        // set key
        const key = item.year;
        // save the value
        years.items[key] = {
            value: years.items[key]?.value || 0,
        };
        years.items[key].value += value;
        // set max value
        years.items[key].max += value;
    });
    // calc max from all years
    Object.keys(years.items).forEach((key) => (years.max = years.max >= years.items[key].value ? years.max : years.items[key].value));
    // return data
    return years;
};
