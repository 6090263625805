import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Select = (props) => {
    const { label, id, defaultValue } = props;
    const [value, setValue] = useState(defaultValue);
    const currentYear = new Date().getFullYear();

    return (
        <label htmlFor={id} className="form-label d-block font-weight-bold">
            {label}
            <select
                name={id}
                id={id}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                className="form-control mt-2"
                value={value}
            >
                <option value={currentYear - 1}>{currentYear - 1}</option>
                <option value={currentYear}>{currentYear}</option>
                <option value={currentYear + 1}>{currentYear + 1}</option>
            </select>
        </label>
    );
};

Select.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    defaultValue: PropTypes.number.isRequired,
};

export default Select;
