import React from 'react';
import PropTypes from 'prop-types';

const ButtonClose = (props) => {
    const { clickHandler, label } = props;

    return (
        <button onClick={clickHandler} aria-label={label} type="button" className="close position-absolute z-index-5 p-0 m-0 bg-white shadow d-flex align-items-center justify-content-center rounded-circle">
            <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" />
            </svg>
        </button>
    );
};

ButtonClose.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

export default ButtonClose;
