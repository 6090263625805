import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Validate from '../../../../lib/Validate';

const FormInput = (props) => {
    const { label, id, defaultValue } = props;
    const [value, setValue] = useState(defaultValue);

    return (
        <label htmlFor={id} className="form-label d-block font-weight-bold">
            {label}
            <input
                type="number"
                onChange={(e) => {
                    Validate.input(e.target);
                    setValue(e.target.value);
                }}
                required
                name={id}
                id={id}
                className="form-control mt-2"
                value={value}
            />
        </label>
    );
};

FormInput.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
};

export default FormInput;
