import React, { Fragment, useRef, useContext, useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Validate from '../../../lib/Validate';
import { NotificationContext, MeasurementFormContext } from '../../../global/context';
import LoadingBar from '../../Loading/Bar';
import ButtonClose from '../../ButtonClose';
import FormInput from './Input';
import SelectMonth from './SelectMonth';
import SelectYear from './SelectYear';
import MeasurementModel from '../../../services/MeasurementModel';
import { text, defaultFormData } from '../../../config';
import 'regenerator-runtime/runtime.js';

const MeasurementForm = () => {
    const form = useRef();
    const notification = useContext(NotificationContext);
    const formContext = useContext(MeasurementFormContext);
    const [showLoadingBar, setShowLoadingBar] = useState(false);
    const animation = useAnimation();

    const modal = {
        visible: {
            opacity: 1,
            y: 0,
        },
        hidden: {
            opacity: 0,
            y: '200%',
        },
    };
    const transition = {
        ease: 'easeInOut',
        duration: 0.3,
    };

    useEffect(() => {
        formContext.setData(defaultFormData);
        animation.start('visible');
    });

    const closeForm = async () => {
        await animation.start('hidden');
        formContext.setIsOpen(false);
    };

    const saveForm = () => {
        // set data
        const { consumned, produced, returned, key, month, year } = form.current.elements;
        const data = {
            consumned: consumned.value,
            produced: produced.value,
            returned: returned.value,
            key: key.value,
            month: month.value,
            year: year.value,
        };
        MeasurementModel.save(data)
            .then(async () => {
                // close the form
                await closeForm();
                // show notification
                notification.setText(text.measurementSaved);
            })
            .catch((err) => {
                notification.setText(err);
            });
    };

    const handleSubmit = (e) => {
        // prevent form submitting
        e.preventDefault();
        // validate form
        if (!Validate.form(form.current)) return;
        // show loading bar
        setShowLoadingBar(true);
        // save data
        saveForm();
    };

    return (
        <Fragment>
            <motion.div transition={{ duration: 0.1 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="modal-bg z-index-10" onClick={closeForm} aria-hidden="true"></motion.div>
            <motion.div transition={transition} initial="hidden" animate={animation} variants={modal} className="modal z-index-15 position-fixed rounded shadow p-3 js-modal">
                <form onSubmit={handleSubmit} noValidate ref={form}>
                    <input type="hidden" name="key" id="key" value={`${formContext.data.year}-${formContext.data.month}`} />
                    <div className="mb-3">
                        <FormInput label={text.inputOne} defaultValue={formContext.data.consumned} id="consumned"></FormInput>
                    </div>
                    <div className="mb-3">
                        <FormInput label={text.inputTwo} defaultValue={formContext.data.produced} id="produced"></FormInput>
                    </div>
                    <div className="mb-3">
                        <FormInput label={text.inputThree} defaultValue={formContext.data.returned} id="returned"></FormInput>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-8 mb-3 pr-2">
                            <SelectMonth label={text.inputFour} defaultValue={Number(formContext.data.month)} id="month" />
                        </div>
                        <div className="col-4 mb-3">
                            <SelectYear label={text.inputFive} defaultValue={Number(formContext.data.year)} id="year" />
                        </div>
                    </div>
                    <input type="submit" value={text.save} className="btn btn-secondary w-100" />
                </form>
                {<ButtonClose clickHandler={closeForm} label={text.closeForm}></ButtonClose>}
                {showLoadingBar ? <LoadingBar /> : ''}
            </motion.div>
        </Fragment>
    );
};

export default MeasurementForm;
