import React from 'react';
import PropTypes from 'prop-types';

const HeaderBox = (props) => {
    const { label, value, padding } = props;
    return (
        <div className={`col-4 ${padding}`}>
            <div className="bg-box rounded p-2">
                <div className="font-size-smaller text-white mb-1">{label}:</div>
                <div className="gamma mb-0 text-white">{value} KW</div>
            </div>
        </div>
    );
};

HeaderBox.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    padding: PropTypes.string.isRequired,
};

export default HeaderBox;
