import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const timeoutTime = 1000;

const NotificationTimer = (props) => {
    const { timer } = props;
    const [countdown, setCountdown] = useState(timer / 1000);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCountdown((c) => c - 1);
        }, timeoutTime);

        return () => clearInterval(intervalId);
    }, []);

    return <div className="notification-timer text-white rounded opacity-8 position-absolute d-flex align-items-center justify-content-center">{countdown}</div>;
};

NotificationTimer.propTypes = {
    timer: PropTypes.number.isRequired,
};

export default NotificationTimer;
