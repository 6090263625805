import React, { Fragment, useEffect, useState, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import firebase from './utils/firebase';
import { databaseBasePath, appStorageKey, defaultFormData } from './config';
import Menu from './components/Menu';
import Loading from './components/Loading';
import { parseData } from './utils/parseData';
import { MeasurementListEditableContext, NotificationContext, OfflineContext, MeasurementsContext, MeasurementFormContext, GraphFilterContext } from './global/context';
import Notification from './components/Notification';
import MeasurementForm from './components/Measurement/Form';

const MeasurementsListPage = React.lazy(() => import('./pages/measurements-list'));
const GraphPage = React.lazy(() => import('./pages/graph'));

const Router = () => {
    const [measurements, setMeasurements] = useState({ items: [], max: 0 });
    const [notificationText, setNotificationText] = useState('');
    const [offline, setOffline] = useState(false);
    const [measurementListEditable, setMeasurementListEditable] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [formData, setFormData] = useState(defaultFormData);
    const [graphFilter, setGraphFilter] = useState('all');

    const loadOnlineDb = () => {
        firebase
            .database()
            .ref(databaseBasePath)
            .on('value', (snapshot, error) => {
                const data = snapshot.val() || {};
                // parse data
                const parsedData = parseData(data);
                // set data
                setMeasurements(parsedData);
                // save data for offline usage
                localStorage[appStorageKey] = JSON.stringify(data);
                // set online status
                setOffline(false);
            });
    };

    const loadOfflineDb = () => {
        // parse data
        const parsedData = parseData(JSON.parse(localStorage[appStorageKey]));
        // set data
        setMeasurements(parsedData);
        // set offline status
        setOffline(true);
    };

    useEffect(() => {
        if (!window.navigator.onLine && localStorage[appStorageKey]) {
            // load offline data
            loadOfflineDb();
        }

        // load online data
        loadOnlineDb();

        return () => {
            firebase.database().ref(databaseBasePath).off();
        };
    }, []);

    return (
        <Fragment>
            <MeasurementListEditableContext.Provider value={{ value: measurementListEditable, setValue: (value) => setMeasurementListEditable(value) }}>
                <OfflineContext.Provider value={offline}>
                    <MeasurementsContext.Provider value={measurements}>
                        <Header />
                    </MeasurementsContext.Provider>
                </OfflineContext.Provider>
            </MeasurementListEditableContext.Provider>
            <NotificationContext.Provider value={{ text: notificationText, setText: (text) => setNotificationText(text) }}>
                <BrowserRouter>
                    <Suspense fallback={<Loading />}>
                        <Switch>
                            <MeasurementFormContext.Provider
                                value={{
                                    data: formData,
                                    isOpen: formOpen,
                                    setData: (data) => setFormData(data),
                                    setIsOpen: (value) => setFormOpen(value),
                                }}
                            >
                                <Route exact path="/">
                                    <MeasurementListEditableContext.Provider value={{ value: measurementListEditable, setValue: (value) => setMeasurementListEditable(value) }}>
                                        <MeasurementsContext.Provider value={measurements}>
                                            <MeasurementsListPage />
                                        </MeasurementsContext.Provider>
                                    </MeasurementListEditableContext.Provider>
                                    <Menu path="home" />
                                </Route>
                                <Route exact path="/graph">
                                    <GraphFilterContext.Provider value={{ value: graphFilter, setValue: (value) => setGraphFilter(value) }}>
                                        <MeasurementsContext.Provider value={measurements}>
                                            <GraphPage />
                                        </MeasurementsContext.Provider>
                                    </GraphFilterContext.Provider>
                                    <Menu path="graph" />
                                </Route>
                                {formOpen ? <MeasurementForm /> : ''}
                            </MeasurementFormContext.Provider>
                        </Switch>
                    </Suspense>
                </BrowserRouter>
                <Notification />
            </NotificationContext.Provider>
        </Fragment>
    );
};

export default Router;
