import { databaseBasePath } from '../../config';
import firebase from '../../utils/firebase';

const db = firebase.database();

const MeasurementModel = {
    save: ({ consumned, produced, returned, month, year, key }) => {
        // set item
        const item = { consumned, produced, returned };
        // set default path
        const path = {
            year,
            month,
        };
        // save data to database
        return new Promise((resolve, reject) => {
            db.ref(`${databaseBasePath}/${path.year}/${path.month}`).set(item, (err) => {
                if (err) {
                    reject(err);
                } else {
                    resolve();
                }
            });
        });
    },

    delete: (year, month) =>
        new Promise((resolve, reject) => {
            db.ref(`${databaseBasePath}/${year}/${month}`).remove((err) => {
                if (err) {
                    reject(err);
                } else {
                    resolve();
                }
            });
        }),
};

export default MeasurementModel;
