import React, { useEffect, useContext, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { notificationShowTime } from '../../config';
import NotificationTimer from './Timer';
import { NotificationContext } from '../../global/context';

const Notification = () => {
    const notification = useContext(NotificationContext);
    const timeoutId = useRef(null);

    useEffect(() => {
        // notification empty, do nothing
        if (notification.text.length === 0) return;
        // set new timer
        timeoutId.current = setTimeout(() => {
            notification.setText('');
            clearTimeout(timeoutId.current);
        }, notificationShowTime);
    }, [notification]);

    return (
        <CSSTransition in={notification.text.length > 0} unmountOnExit timeout={{ enter: 300, exit: 300 }}>
            <div className="notification shadow text-white rounded z-index-15 position-fixed font-size-smaller justify-content-center p-2 js-notification">
                {notification.text}
                <NotificationTimer timer={notificationShowTime} />
            </div>
        </CSSTransition>
    );
};

export default Notification;
